<template>
  <div>
    <UISelectFixed type="RIT_OPVOLGING" :disabled="disabled" nullable nullableLabel="Geen opvolging"
      :class="{ 'text-gray-400': selected === null, 'border-yellow-400 bg-yellow-200 font-bold rounded': selected !== null }"
      :label="''" class="small w-full p-0" v-model="selected" />
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import { useVModel } from '@vueuse/core'

const emit = defineEmits(['update:status'])
const props = defineProps({
  disabled: Boolean,
  status: [Number, String],
})

const selected = useVModel(props, 'status', emit, { deep: true, passive: true })
</script>
